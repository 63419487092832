<template>
  <ion-list>
    <ion-item class="ion-no-padding">
      <ion-label> Not viewed </ion-label>
      <ion-badge slot="end" color="dark" class="badge">
        {{ count("Created") }}
      </ion-badge>
    </ion-item>
    <ion-item class="ion-no-padding">
      <ion-label> Answered </ion-label>
      <ion-badge slot="end" color="success" class="badge">
        {{ count("Answered") }}
      </ion-badge>
    </ion-item>
    <ion-item class="ion-no-padding">
      <ion-label> Skipped </ion-label>
      <ion-badge slot="end" color="warning" class="badge">
        {{ count("Skiped") }}
      </ion-badge>
    </ion-item>
  </ion-list>

  <div class="container" v-if="allAnswers">
    <ion-badge
      :color="getBadgeColor(item)"
      class="badge-sm"
      v-for="(item, index) in allAnswers"
      :key="item.id"
      @click="loadQuestion(item.exam_question_id)"
    >
      {{ index + 1 }}
    </ion-badge>
  </div>
</template>

<script>
import { IonBadge, IonList, IonItem, IonLabel } from "@ionic/vue";
import moment from "moment";

export default {
  props: ["allAnswers", "exam_name", "examSubject", "examAnswerStates"],
  emits: ["loadQuestion"],
  components: {
    IonBadge,
    IonList,
    IonItem,
    IonLabel,
  },

  data() {
    return {
      timer: "--:--:--",
      timer_color: "dark",
    };
  },

  methods: {
    set_timer() {
      setInterval(() => {
        const current_time_stamp = moment().format("x");
        const event_time_stamp = moment(
          this.examSubject.exam_schedule.end
        ).format("x");
        const diff = event_time_stamp - current_time_stamp;
        const duration = moment.duration(diff);
        this.timer =
          duration.asHours() < -24
            ? "--:--:--"
            : `${Math.abs(duration.hours())}:${Math.abs(
                duration.minutes()
              )}:${Math.abs(duration.seconds())}`;
      }, 1000);
    },

    getBadgeColor(item) {
      let exam_answer_state_id = item.exam_answer_state_id;
      let exam_answer_state = this.examAnswerStates.find(
        (state) => state.id == exam_answer_state_id
      );
      let status = exam_answer_state.name;

      if (status == "Created") {
        return "dark";
      }

      if (status == "Answered") {
        return "success";
      }

      return "warning";
    },

    count(status) {
      return this.allAnswers.filter(
        (item) =>
          item.exam_answer_state_id ==
          this.examAnswerStates.find((state) => state.name == status).id
      ).length;
    },

    loadQuestion(id) {
      this.$emit("loadQuestion", id);
    },
  },

  mounted() {
    this.set_timer();
  },
};
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.badge {
  border-radius: 35%;
  min-width: 1.8rem;
  min-height: 1.8rem;
  text-align: center;
  padding: 0.5rem;
}
.badge-sm {
  border-radius: 35%;
  min-width: 1.6rem;
  min-height: 1.6rem;
  text-align: center;
  padding: 0.4rem;
  margin: 0.4rem;
}
</style>