<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-title>{{ title }}</ion-title>
        <ion-buttons slot="end">
          <ion-button @click="closeModal()">Close</ion-button>
        </ion-buttons>
      </ion-toolbar>
      <slot name="search-bar"></slot>
    </ion-header>
    <ion-content class="ion-padding">
      <slot></slot>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonPage,
  IonContent,
  IonHeader,
  IonTitle,
  IonToolbar,
  IonButtons,
  IonButton,
} from "@ionic/vue";

export default {
  props: {
    title: { type: String, default: "Modal" },
  },
  emits: ["closeModal"],
  components: {
    IonPage,
    IonContent,
    IonHeader,
    IonTitle,
    IonToolbar,
    IonButtons,
    IonButton,
  },

  methods: {
    closeModal() {
      this.$emit("closeModal");
    },
  },
};
</script>