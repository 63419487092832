<template>
  <base-layout
    :title="subject_name"
    :backLink="backLink"
    :reloadable="reloadable"
    :footer="true"
  >
    <template v-slot:search-bar>
      <ion-toolbar size="small">
        <ion-title slot="start" class="ion-text-capitalize" size="small">
          <ion-text color="dark">{{ exam_name }}</ion-text>
        </ion-title>

        <ion-title slot="end" class="ion-text-capitalize" size="small">
          <ion-text v-if="examSubject" :color="timer_color">
            {{ timer }}
          </ion-text>
        </ion-title>
      </ion-toolbar>
    </template>

    <ion-card v-if="loadedQuestion && loadedAnswer">
      <ion-card-header class="ion-no-padding">
        <ion-item lines="none">
          <ion-card-subtitle slot="start" color="dark">
            Q {{ loadedQuestionIndex + 1 }}
          </ion-card-subtitle>
          <ion-card-subtitle
            slot="end"
            class="ion-text-end"
            color="dark"
            v-if="examSubject"
          >
            marks:
            <span style="color: green">{{ loadedQuestion.marks }}</span> (<span
              style="color: red"
            >
              -{{
                (examSubject.negative_percentage / 100) * loadedQuestion.marks
              }} </span
            >)
          </ion-card-subtitle>
        </ion-item>
      </ion-card-header>

      <ion-card-content
        style="color: black"
        v-html="loadedQuestion.description"
      ></ion-card-content>

      <section>
        <ion-card-content
          v-if="loadedQuestion.exam_question_type.name == 'Descriptive'"
        >
          <editor
            class="boxsizingBorder"
            :api-key="tinyMceApiKey"
            :init="tinyMceInit"
            placeholder="Type Answer..."
            v-model="descriptive_answer"
          ></editor>
          <!-- :class="{ 'color-danger': v$.descriptive.$error }" -->
        </ion-card-content>

        <ion-card-content
          v-if="loadedQuestion.exam_question_type.name == 'Objective'"
          class="ion-no-padding"
        >
          <ion-list>
            <ion-list lines="none">
              <ion-radio-group v-model="exam_question_option_id">
                <ion-list-header>
                  <ion-label>Select answer:</ion-label>
                </ion-list-header>
                <ion-item
                  v-for="item in loadedQuestion.exam_question_options"
                  :key="item.id"
                >
                  <ion-label> {{ item.description }} </ion-label>
                  <ion-radio slot="start" :value="item.id"></ion-radio>
                </ion-item>
              </ion-radio-group>
            </ion-list>
          </ion-list>
        </ion-card-content>
      </section>
    </ion-card>

    <ion-card v-else>
      <ion-card-content> Loading.... </ion-card-content>
    </ion-card>

    <template v-slot:footer>
      <ion-toolbar>
        <ion-button
          slot="start"
          :color="
            nextQuestion && loadedAnswer.exam_answer_state_id != 2
              ? 'warning'
              : 'medium'
          "
          fill="solid"
          :disabled="!nextQuestion || loadedAnswer.exam_answer_state_id == 2"
          @click="updateAnswer(false)"
        >
          Skip & Next
        </ion-button>
        <ion-title align="center">
          <ion-button
            size="small"
            fill="clear"
            @click="setStatsModalState(true)"
          >
            <ion-icon
              slot="icon-only"
              :ios="appsOutline"
              :md="appsOutline"
            ></ion-icon>
          </ion-button>
        </ion-title>
        <ion-button
          slot="end"
          color="success"
          fill="solid"
          @click="updateAnswer(true)"
        >
          {{ nextQuestion ? "Save & Next" : "Save & View" }}
        </ion-button>
      </ion-toolbar>
    </template>

    <ion-modal
      :is-open="statsModalState"
      @didDismiss="setStatsModalState(false)"
    >
      <base-modal :title="subject_name" @closeModal="setStatsModalState(false)">
        <template v-slot:search-bar>
          <ion-toolbar size="small">
            <ion-title slot="start" class="ion-text-capitalize" size="small">
              <ion-text color="dark">Total Q: {{ allAnswers.length }}</ion-text>
            </ion-title>

            <ion-title slot="end" class="ion-text-capitalize" size="small">
              <ion-text v-if="examSubject" :color="timer_color">
                {{ timer }}
              </ion-text>
            </ion-title>
          </ion-toolbar>
        </template>

        <online-exam-stats
          @loadQuestion="loadQuestionFromStatsPage"
          :allAnswers="allAnswers"
          :exam_name="exam_name"
          :examSubject="examSubject"
          :examAnswerStates="examAnswerStates"
        ></online-exam-stats>
      </base-modal>
    </ion-modal>
  </base-layout>
</template>

<script>
import BaseLayout from "../../Base/BaseLayout";

import {
  IonToolbar,
  IonTitle,
  // IonButtons,
  IonModal,
  IonText,
  IonButton,
  IonIcon,
  IonCard,
  IonCardHeader,
  IonCardContent,
  IonCardSubtitle,
  IonList,
  IonItem,
  IonRadioGroup,
  IonRadio,
  IonListHeader,
  IonLabel,
  loadingController,
  alertController,
} from "@ionic/vue";

import { appsOutline } from "ionicons/icons";
import moment from "moment";

import Editor from "@tinymce/tinymce-vue";
import base_url from "../../../apis/base_url";

import BaseModal from "../../Base/BaseModal";
import OnlineExamStats from "../../Components/Exam/OnlineExam/OnlineExamStats.vue";

export default {
  props: ["exam_subject_id", "exam_name", "subject_name"],

  components: {
    BaseLayout,
    BaseModal,
    OnlineExamStats,
    IonModal,
    Editor,

    IonCard,
    IonCardHeader,
    IonCardContent,
    IonCardSubtitle,
    IonList,
    IonItem,
    IonRadioGroup,
    IonRadio,
    IonListHeader,
    IonLabel,
    IonToolbar,
    IonTitle,
    IonText,
    IonButton,
    IonIcon,
  },

  data() {
    return {
      backLink: "exam.list",
      reloadable: false,
      statsModalState: false,
      timer: "--:--:--",
      timer_color: "dark",
      appsOutline,
      base_url,

      descriptive_answer: null,
      exam_question_option_id: null,

      xhrError: {
        header: null,
        description: null,
      },

      tinyMceApiKey: "v7mewwx48ga0tsajrox6aqqbg0uwngv2g8bmw2eac72nzhjw",
      tinyMceInit: {
        branding: false,
        height: 400,
        statusbar: true,
        menubar: false,
        mobile: {
          toolbar_mode: "floating",
        },
        plugins: [
          "code charmap table image imagetools lists fullscreen wordcount",
        ],
        toolbar_groups: {
          formatgroup: {
            icon: "format",
            tooltip: "Formatting",
            items:
              "bold italic underline strikethrough superscript subscript removeformat | forecolor backcolor",
          },
          paragraphgroup: {
            icon: "paragraph",
            tooltip: "Paragraph format",
            items:
              "h1 h2 h3 | alignleft aligncenter alignright alignjustify | indent outdent",
          },
          insertgroup: {
            icon: "plus",
            tooltip: "Insert",
            items: "link image table emoticons charmap hr | bullist numlist",
          },
        },
        toolbar:
          "code undo redo | formatgroup paragraphgroup insertgroup | image",

        images_upload_handler: this.images_uploader,
      },
    };
  },

  computed: {
    examSubject() {
      return this.$store.getters["examSubject/specificResource"];
    },
    allAnswers() {
      return this.$store.getters["examAnswer/allResources"];
    },

    examAnswerStates() {
      return this.$store.getters["attribute/examAnswerState"];
    },

    loadedQuestion() {
      let loaded_question =
        this.$store.getters["examQuestion/specificResource"];
      return loaded_question ? loaded_question : false;
    },

    loadedQuestionIndex() {
      let allAnswers = this.allAnswers;
      let loaded_question = this.loadedQuestion;
      let loaded_question_index = allAnswers.findIndex(
        (answer) => answer.exam_question_id == loaded_question.id
      );
      return loaded_question_index;
    },

    loadedAnswer() {
      if (this.loadedQuestion && this.allAnswers) {
        let loaded_answer = this.allAnswers.find(
          (answer) => answer.exam_question_id == this.loadedQuestion.id
        );
        return loaded_answer ? loaded_answer : false;
      } else {
        return false;
      }
    },

    nextQuestion() {
      if (this.loadedAnswer) {
        // let current_question = this.allAnswers.find(
        //   (answer) => answer.exam_question_id == this.loadedQuestion.id
        // );
        let next_question = this.allAnswers[this.loadedQuestionIndex + 1];

        return next_question ? next_question : false;
      } else {
        return false;
      }
    },

    original_images_in_description() {
      let image_srcs = [];
      if (this.loadedAnswer.description) {
        let images = this.loadedAnswer.description.match(/<img.*?src="(.*?)"/g);
        if (images) {
          images.forEach((image) => {
            let src = image.match(/src="(.*?)"/)[1];
            let file_name = src.split("/").pop();
            image_srcs.push(file_name);
          });
        }
      }
      return image_srcs;
    },

    current_images_in_description() {
      let image_srcs = [];
      if (this.descriptive_answer) {
        let images = this.descriptive_answer.match(/<img.*?src="(.*?)"/g);
        if (images) {
          images.forEach((image) => {
            let src = image.match(/src="(.*?)"/)[1];
            let file_name = src.split("/").pop();
            image_srcs.push(file_name);
          });
        }
      }
      return image_srcs;
    },

    orphan_images() {
      let orphan_images = [];
      let original_images = this.original_images_in_description;
      let current_images = this.current_images_in_description;
      original_images.forEach((image) => {
        if (!current_images.includes(image)) {
          orphan_images.push(image);
        }
      });
      return orphan_images;
    },

    new_images() {
      let new_images = [];
      let current_images = this.current_images_in_description;
      let original_images = this.original_images_in_description;
      current_images.forEach((image) => {
        if (!original_images.includes(image)) {
          new_images.push(image);
        }
      });
      return new_images;
    },

    updated_descriptive_answer() {
      const temp_dir_name = "/storage/tiny_mce_uploaded_imgs";
      const updated_dir_name = "/storage/exam_answer_images";

      console.log(temp_dir_name, updated_dir_name);
      let updated_descriptive_answer = this.descriptive_answer;
      updated_descriptive_answer = updated_descriptive_answer.replace(
        new RegExp(temp_dir_name, "g"),
        updated_dir_name
      );
      return updated_descriptive_answer;
    },
  },

  watch: {
    loadedAnswer(newLoadedAnswer) {
      if (this.loadedQuestion.exam_question_type.name == "Descriptive") {
        this.descriptive_answer = newLoadedAnswer.description;
      }

      if (this.loadedQuestion.exam_question_type.name == "Objective") {
        this.exam_question_option_id = newLoadedAnswer.exam_question_option_id;
      }
    },
  },

  methods: {
    loader(message) {
      const loading = loadingController.create({
        message: message,
        backdropDismiss: false,
      });

      return loading;
    },

    alerter(header, message, buttons = ["close"]) {
      const alert = alertController.create({
        header: header,
        message: message,
        buttons: buttons,
      });

      return alert;
    },

    async getAllAnswers() {
      await this.$store.dispatch(
        "examAnswer/allResources",
        this.exam_subject_id
      );
      await this.loadExamQuestion(this.allAnswers[0].exam_question_id);
    },

    async getExamSubject() {
      await this.$store.dispatch(
        "examSubject/specificResource",
        this.exam_subject_id
      );
    },

    async getExamAnswerStates() {
      await this.$store.dispatch("attribute/examAnswerState");
    },

    async loadQuestionFromStatsPage(id) {
      await this.loadExamQuestion(id);
      this.setStatsModalState(false);
    },

    async loadExamQuestion(exam_question_id) {
      await this.$store.dispatch(
        "examQuestion/specificResource",
        exam_question_id
      );
    },

    set_timer() {
      setInterval(() => {
        const current_time_stamp = moment().format("x");
        const event_time_stamp = moment(
          this.examSubject.exam_schedule.end
        ).format("x");
        const diff = event_time_stamp - current_time_stamp;
        const duration = moment.duration(diff);
        this.timer =
          duration.asHours() < -24
            ? "--:--:--"
            : `${Math.abs(duration.hours())}:${Math.abs(
                duration.minutes()
              )}:${Math.abs(duration.seconds())}`;
      }, 1000);
    },

    setStatsModalState(state) {
      this.statsModalState = state;
    },

    async resizeImage(image) {
      var canvas = document.createElement("canvas");
      var ctx = canvas.getContext("2d");
      // resize image
      var MAX_WIDTH = 720;
      var MAX_HEIGHT = 720;
      var width = image.width;
      var height = image.height;
      if (width > height) {
        if (width > MAX_WIDTH) {
          height *= MAX_WIDTH / width;
          width = MAX_WIDTH;
        }
      } else {
        if (height > MAX_HEIGHT) {
          width *= MAX_HEIGHT / height;
          height = MAX_HEIGHT;
        }
      }
      canvas.width = width;
      canvas.height = height;

      //return resized image as base64
      ctx.drawImage(image, 0, 0, width, height);
      var dataurl = canvas.toDataURL("image/jpeg", 80);
      return dataurl;
    },

    async convertImageToBase64(blob) {
      const convertBlobToBase64 = (blob) =>
        new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onerror = reject;
          reader.onload = () => {
            resolve(reader.result);
          };
          reader.readAsDataURL(blob);
        });
      return convertBlobToBase64(blob);
    },

    async images_uploader(blobInfo, success, failure) {
      let base64 = await this.convertImageToBase64(blobInfo.blob());
      let img = await document.createElement("img");
      img.src = base64;
      await img.complete;
      let dataurl = await this.resizeImage(img);

      let response = await this.$store.dispatch("tinyMce/uploadImage", {
        base64: dataurl,
      });
      // console.log('here', response)

      if (response.status == 200) {
        success(base_url + response.data.image_url);
      } else {
        failure("Error uploading image");
      }
    },

    setErrorResponse(error) {
      if (error.response) {
        this.xhrError.header = error.response.data.header;
        this.xhrError.message = error.response.data.message;
      } else if (error.request) {
        this.xhrError.header = error.message || error.request;
        this.xhrError.message = "Please check your connection and try again";
      } else {
        this.xhrError.header = error.message;
        this.xhrError.message = "Something went wrong. Try again later";
      }
    },

    async updateAnswer(save_answer = true) {
      let exam_answer_skiped_state_id = await this.examAnswerStates.find(
        (state) => state.name == "Skiped"
      ).id;

      let exam_answer_answered_state_id = await this.examAnswerStates.find(
        (state) => state.name == "Answered"
      ).id;

      let data = {
        id: this.loadedAnswer.id,
        exam_question_type: this.loadedQuestion.exam_question_type.name,
        exam_subject_id: this.examSubject.id,
        orphan_images: this.orphan_images,
        new_images: this.new_images,
        exam_answer_state_id: save_answer
          ? exam_answer_answered_state_id
          : exam_answer_skiped_state_id,
      };

      console.log(data);

      if (
        save_answer == true &&
        this.loadedQuestion.exam_question_type.name == "Descriptive"
      ) {
        data["description"] = this.updated_descriptive_answer;
      }

      if (
        save_answer == true &&
        this.loadedQuestion.exam_question_type.name == "Objective"
      ) {
        data["exam_question_option_id"] = this.exam_question_option_id;
      }

      const spinner = await this.loader("Saving...");
      spinner.present();

      try {
        await this.$store.dispatch("examAnswer/updateResource", data);
        this.descriptive_answer = null;
        this.exam_question_option_id = null;

        this.gotoNextQuestion();

        spinner.dismiss();
      } catch (error) {
        spinner.dismiss();
        this.setErrorResponse(error);
        const alertDialog = await this.alerter(
          this.xhrError.header,
          this.xhrError.message
        );
        alertDialog.present();
      }
    },

    async gotoNextQuestion() {
      let nextQuestion = this.nextQuestion;
      if (nextQuestion) {
        await this.loadExamQuestion(this.nextQuestion.exam_question_id);
      } else {
        this.setStatsModalState(true);
      }
    },
  },

  created() {
    this.getAllAnswers();
    this.getExamSubject();
    this.set_timer();
    this.getExamAnswerStates();
  },
};
</script>

<style scoped>
ion-toolbar {
  --min-height: 40px !important;
}
</style>
